/* You can add global styles to this file, and also import other style files */
body {
  display: flex;
}

.feature-modal .ant-modal-content{
  border-radius: 8px;
  overflow: hidden;
}

.yoma-table {
  height: 100%;
  overflow: auto;
  nz-spin {
    height: inherit;
    > div {
      height: inherit;
      display: flex;
      flex-direction: column;
      overflow: auto;
      div.ant-table {
        flex: 1;
        overflow: auto;
      }
    }
  }
  thead>tr>th {
      position: sticky;
      top: 0;
      z-index: 1;
  }
}
.header-container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2em;
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
  }
  .actions {
    display: flex;
    gap: 6px;
    align-items: center;
    .search:hover {
      cursor: pointer;
    }
  }
}

.content {
  flex: 1;
  overflow: auto;
  .ant-tabs-content {
    height: 100%;
  }
}
.customer-form .ant-form-item{
  margin-bottom: 12px;
}

